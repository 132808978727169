<template>
  <v-container class="fill-height" v-if="loading">
    <v-row dense align="center">
      <v-col cols="8" offset="2">
        <v-row class="text-center"   >
          <v-col cols="12">
            <h3>Please wait while we sign you in</h3>
          </v-col>
          <v-col cols="12">
            <v-progress-linear color="color3" v-bind:indeterminate="true"></v-progress-linear>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
  <v-container class="fill-height" v-else-if="errors.length > 0">
    <v-row dense align="center">
      <v-col cols="8" offset="2">
        <v-row class="text-center"   >
          <v-col cols="12">
            <v-alert text type="error" value="true">
              Oops, looks like you got a bad link!
            </v-alert>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SDK from '@/VBL'
import * as actions from '@/store/ActionTypes'
import * as mutations from '@/store/MutationTypes'

export default {
  props: ['linkId'],
  data () {
    return {
      loading: true,
      errors: []
    }
  },
  methods: {
    signIn () {
      this.$store.commit(mutations.LOADING, 'Magic')
      const sdk = new SDK(this.axios)
      sdk.user.loginViaMagicLink(this.linkId)
        .then((response) => {
          this.$store.commit(mutations.SET_MAGICLINKTIME, response.data.data)
          this.$store.dispatch(actions.ONLOGIN, 'magiclink')
          this.$router.push({ name: 'me' })
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          console.log(error.response.data)
        })
        .finally(() => {
          this.$store.commit(mutations.LOADING_COMPLETE, 'Magic')
        })
    }
  },
  created () {
    this.signIn()
  }
}
</script>

<style>

</style>
